import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import * as landingStyles from "./landing.module.scss";
import Layout from "../layout/layout";
import SEO from "../seo";
import { graphql, Link, useStaticQuery } from "gatsby";
import { joinClasses } from "../utils";

const Landing = (props) => {
  const data = useStaticQuery(graphql`
    query LandingImages {
      allContentfulLandingImage {
        edges {
          node {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 1800
                    placeholder: BLURRED
                    jpgOptions: { quality: 85 }
                    webpOptions: { quality: 90 }
                  )
                }
              }
            }
            id
            mobile
            whiteText
            isDefault
          }
        }
      }
    }
  `);

  const mobileSrcs = [];
  const desktopSrcs = [];

  data.allContentfulLandingImage.edges
    .map((edge) => {
      return edge.node;
    })
    // Don't choke if there's an error when uploading to Contentful
    .filter(
      (node) => !!node?.image?.localFile?.childImageSharp?.gatsbyImageData
    )
    .forEach((node) => {
      if (node.mobile) {
        mobileSrcs.push(node);
      } else {
        desktopSrcs.push(node);
      }
    });

  const switchBackground = props.location?.state?.switchBackground;
  const getSrc = (sources) =>
    (!switchBackground && sources.find((src) => src.isDefault)) ||
    sources[Math.floor(Math.random() * sources.length)];

  const mobileSrc = getSrc(mobileSrcs);
  const desktopSrc = getSrc(desktopSrcs);

  return (
    <Layout
      mobileWhite={mobileSrc.whiteText}
      desktopWhite={desktopSrc.whiteText}
    >
      <SEO title="Sydney SG" />
      <Link to="/art-direction" className={landingStyles.link}></Link>
      <GatsbyImage
        image={mobileSrc.image.localFile.childImageSharp.gatsbyImageData}
        loading="eager"
        objectFit="cover"
        className={joinClasses(
          landingStyles.image,
          landingStyles.image__mobile,
        )}
      />
      <GatsbyImage
        image={desktopSrc.image.localFile.childImageSharp.gatsbyImageData}
        loading="eager"
        objectFit="cover"
        className={joinClasses(
          landingStyles.image,
          landingStyles.image__desktop,
        )}
      />
    </Layout>
  );
};

export default Landing;
